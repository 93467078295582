import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import sendWhite from '../../../../images/icons/send-white.png';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #0E1828;
    color: #fff;
    overflow: hidden;
    svg.left,
    svg.right {
        display: none;
    }
    .outer-wrapper {
        display: block;
        position: relative;
        width: 100%;
        z-index: 1;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            .column {
                display: block;
                flex: 0 1 auto;
                width: 100%;
                padding: 7rem 4rem;
                &.content-container {
                    .content {
                        display: block;
                        position: relative;
                        width: 100%;
                        h2 {
                            font-size: 3.8rem;
                            line-height: 1.2;
                            font-weight: 600;
                            margin-bottom: 2.4rem;
                            span {
                                display: block;
                                &.italic {
                                    font-family: "Source Serif Pro";
                                    font-weight: 300;
                                    font-style: italic;
                                }
                            }
                        }
                        .address-container {
                            .address {
                                span {
                                    &.title {
                                        display: block;
                                        font-size: 1rem;
                                        line-height: 1.5;
                                        letter-spacing: 0.3em;
                                        text-transform: uppercase;
                                        margin-bottom: .5rem;
                                    }
                                }
                                p {
                                    font-size: 1.4rem;
                                    line-height: 1.36;
                                    margin-bottom: 2.4rem;
                                }
                                a {
                                    display: inline-block;
                                    font-size: 1.4rem;
                                    line-height: 1.36;
                                    margin-bottom: 2.4rem;
                                    color: #fff;
                                    text-decoration: none;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    svg {
                                        display: inline-block;
                                        margin-right: 1.8rem;
                                        vertical-align: middle;
                                        width: auto;
                                        height: 1.9rem;
                                    }
                                }
                            }
                        }
                    }
                }
                &.form-container {
                    form {
                        margin: 0;
                        h2 {
                            font-family: "Poppins";
                            font-size: 2.6rem;
                            line-height: 1.2;
                            font-weight: 400;
                            color: #ECECEC;
                            margin-bottom: 4rem;
                        }
                        p.error-message {
                            display: block;
                            font-family: "Poppins";
                            font-size: 2rem;
                            line-height: 1.2;
                            font-weight: 400;
                            margin-bottom: 2rem;
                            &.error {
                                color: #E2246E;
                            }
                        }
                        label {
                            display: block;
                            width: 100%;
                            font-size: 0;
                            margin-bottom: 2rem;
                            input[type="text"],
                            input[type="email"],
                            textarea {
                                display: block;
                                width: 100%;
                                background: none;
                                font-family: "Poppins";
                                font-size: 1.6rem;
                                line-height: 1.625;
                                font-weight: 400;
                                color: #ECECEC;
                                -webkit-appearance: none;
                                appearance: none;
                                border: none;
                                border-bottom: 1px solid #ECECEC;
                                border-radius: 0;
                                padding: 0;
                                &:focus,
                                &:active {
                                    outline: none;
                                    border-bottom: 1px solid #E2246E;
                                }
                            }
                        }
                        button[type="submit"] {
                            display: inline-block;
                            position: relative;
                            font-family: "Poppins";
                            font-size: 1.1rem;
                            line-height: 1.5;
                            font-weight: 600;
                            letter-spacing: 0.3em;
                            text-transform: uppercase;
                            color: #fff;
                            background: url(${sendWhite}) right 2rem center/2.2rem auto no-repeat #E2246E;
                            padding: 1.5rem 6.2rem 1.5rem 2rem;
                            border: none;
                            border-radius: 24px;
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        svg.left,
        svg.right {
            display: block;
            position: absolute;
            top: 0;
            width: auto;
            height: 100%;
            pointer-events: none;
            z-index: 0;
        }
        svg.left {
            left: 0;
        }
        svg.right {
            right: 0;
        }
        .outer-wrapper {
            position: relative;
            z-index: 1;
            .inner-wrapper {
                flex-flow: row nowrap;
                align-items: center;
                min-height: 600px;
                    .column {
                        width: 50%;
                        &.content-container {
                            padding: 10rem 5rem 10rem 16rem;
                            .content {
                                span.subtitle {
                                    margin-bottom: 2rem;
                                }
                                h2 {
                                    font-size: 4.8rem;
                                }
                                img {
                                    display: block;
                                    width: 100%;
                                    max-width: 100%;
                                    height: auto;
                                    margin: 2.4rem auto 0;
                                }
                            }
                        }
                        &.form-container {
                            padding: 10rem 16rem 10rem 5rem;
                        }
                    }
                }
            }
        }
    }
`;
const TextAndForm = ({ ...props }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company_name: '',
        message: '',
        success: false,
        successMessage: '',
        error: false,
        errorMessage: 'Something went wrong. Please try again later.',
    });

    const handleSubmit = (e, formData) => {
        e.preventDefault();
        axios
            .post('/contact-delivery', formData)
            .then(function (response) {
                console.log(response);
                setFormData({
                    ...formData,
                    success: true,
                    successMessage: "Your form has been submitted successfully. We'll be in touch!",
                });
            })
            .catch(function (error) {
                console.log(error);
                setFormData({ ...formData, error: true });
            });
    };

    return (
        <Wrapper id={props.id} className={props.background}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="column content-container">
                        <div className="content">
                            <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                            <div className="address-container">
                                <div className="address">
                                    <span className="title">Leeds office</span>
                                    <p>
                                        8 Park Row,
                                        <br />
                                        Leeds,
                                        <br />
                                        LS1 5HD
                                    </p>
                                    <a
                                        className="directions"
                                        href="https://goo.gl/maps/TfAk2zyFso84GyKdA"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <svg
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M16.9939 5.61832C16.8643 4.18775 16.389 2.96756 15.784 1.91567C15.2223 1.49492 14.6606 1.15832 14.0556 0.905862C12.7593 0.316805 11.2902 0.022277 9.90745 0.106428C9.38892 0.148503 8.8704 0.190579 8.35188 0.316805C7.79015 0.443032 7.22841 0.611334 6.70989 0.863787C6.10495 1.11624 5.50001 1.49492 4.98148 1.8736C4.29012 3.00964 3.90123 4.2719 3.72839 5.57624C3.59876 6.54398 3.68518 7.51171 3.90123 8.52153C4.11728 9.48926 4.46296 10.4149 4.93827 11.2564L10.3395 20.0923L15.8272 11.2985C16.3026 10.457 16.6482 9.53134 16.8211 8.5636C16.9939 7.55379 17.0803 6.58605 16.9939 5.61832ZM10.3395 3.00964C12.3704 3.00964 14.0124 4.60851 14.0124 6.58605C14.0124 8.5636 12.3704 10.1625 10.3395 10.1625C8.30867 10.1625 6.66668 8.5636 6.66668 6.58605C6.66668 4.60851 8.30867 3.00964 10.3395 3.00964Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                        Get Directions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <svg className="left" viewBox="0 0 490 600" fill="none">
                <g clipPath="url(#clip0navy)">
                    <path
                        d="M1554.89 1232.2C1354.62 1214.75 1165.99 1159.92 994.803 1074.36C639.968 898.238 360.753 590.864 219.484 217.031C-107.098 302.597 40.8196 609.14 40.8196 809.349C40.8196 910.699 123.088 986.297 141.37 1081.83C142.201 1083.49 143.032 1084.32 143.032 1084.32C143.032 1084.32 253.555 1167.4 439.698 1239.67C698.138 1341.02 1102 1421.6 1554.89 1232.2Z"
                        fill="#202D46"
                    />
                    <path
                        d="M969.873 -620.359C1139.4 -707.586 1325.54 -764.077 1523.32 -784.845C1269.03 -885.365 874.308 -965.116 427.232 -777.369C334.991 -738.324 240.258 -688.479 143.862 -624.512C143.862 -624.512 143.862 -624.512 143.862 -623.682C-445.314 265.211 105.636 1032.82 142.2 1081.83C123.918 986.294 114.777 886.605 114.777 786.085C114.777 585.877 152.172 393.976 219.483 217.859C359.09 -146.006 627.502 -444.242 969.873 -620.359Z"
                        fill="url(#paint0_linear_navy)"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_navy"
                        x1="284.737"
                        y1="424.226"
                        x2="-60.3225"
                        y2="-617.245"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0E1828" />
                        <stop offset="1" stopColor="#446394" />
                    </linearGradient>
                    <clipPath id="clip0navy">
                        <rect
                            width="490"
                            height="600"
                            fill="white"
                            transform="translate(490 600) rotate(-180)"
                        />
                    </clipPath>
                </defs>
            </svg>
            <svg className="right" viewBox="0 0 602 601" fill="none">
                <g clipPath="url(#clip1navy)">
                    <path
                        d="M-952.895 -631.198C-752.624 -613.752 -563.988 -558.923 -392.803 -473.357C-37.9678 -297.24 241.247 10.134 382.516 383.967C709.098 298.401 561.18 -8.14227 561.18 -208.351C561.18 -309.701 478.912 -385.299 460.63 -480.834C459.799 -482.495 458.968 -483.326 458.968 -483.326C458.968 -483.326 348.445 -566.4 162.302 -638.675C-96.1376 -740.025 -500.002 -820.607 -952.895 -631.198Z"
                        fill="#202D46"
                    />
                    <path
                        d="M-367.872 1221.36C-537.396 1308.58 -723.539 1365.07 -921.316 1385.84C-667.031 1486.36 -272.308 1566.11 174.768 1378.37C267.009 1339.32 361.742 1289.48 458.138 1225.51C458.138 1225.51 458.138 1225.51 458.138 1224.68C1047.31 335.787 496.364 -431.817 459.8 -480.831C478.082 -385.296 487.223 -285.607 487.223 -185.087C487.223 15.1212 449.828 207.022 382.517 383.139C242.91 747.004 -25.502 1045.24 -367.872 1221.36Z"
                        fill="url(#paint1_linear_navy)"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint1_linear_navy"
                        x1="5.09304"
                        y1="338.488"
                        x2="490.838"
                        y2="1004.03"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0E1828" />
                        <stop offset="1" stopColor="#446394" />
                    </linearGradient>
                    <clipPath id="clip1navy">
                        <rect
                            width="602"
                            height="600"
                            fill="white"
                            transform="translate(0 0.499023)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </Wrapper>
    );
};

export default TextAndForm;
