import React from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const Wrapper = styled.div`
    @keyframes titleText {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes transformImage {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
    @keyframes revealWidth {
        0% {
            width: 100%;
        }
        100% {
            width: 0%;
        }
    }
    @keyframes reveal {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes bounce {
        20%,
        100% {
            margin-top: 0;
        }
        0% {
            margin-top: 0;
        }
        4% {
            margin-top: -0.5rem;
        }
        8% {
            margin-top: 0.5rem;
        }
        12% {
            margin-top: -0.5rem;
        }
        16% {
            margin-top: 0.5rem;
        }
    }

    display: block;
    position: relative;
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background: ${(props) => props.backgroundColor};
    overflow: hidden;
    .outer-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        .inner-wrapper {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 12rem 0 0 0;
            position: relative;
            .column {
                display: block;
                flex: 0 1 auto;
                width: 100%;
                &.content-container {
                    padding: 0 3rem 3rem 3rem;
                    z-index: 1;
                    span.intro {
                        display: none;
                        position: relative;
                        font-family: 'Poppins';
                        font-size: 1rem;
                        line-height: 1.5;
                        font-weight: 600;
                        letter-spacing: 0.3em;
                        text-transform: uppercase;
                        color: ${(props) => props.textColor};
                        margin-bottom: 1rem;
                        opacity: 0;
                        animation: reveal 1s ease-out 1.5s forwards;
                        &:before {
                            display: block;
                            position: absolute;
                            content: '';
                            top: 50%;
                            right: calc(100% + 2rem);
                            transform: translateY(-50%);
                            width: 100%;
                            height: 1px;
                            background: ${(props) => props.textColor};
                        }
                    }
                    h1 {
                        display: block;
                        font-family: 'Poppins';
                        font-size: 4rem;
                        line-height: 1;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: ${(props) => props.textColor};
                        margin-bottom: 4rem;
                        word-wrap: normal;
                        word-break: keep-all;
                        hyphens: none;
                        position: relative;
                        .line {
                            display: block;
                            position: relative;
                            overflow-y: hidden;
                            width: 200%;
                            span {
                                &.neon-pink {
                                    color: #e2246e;
                                }
                                &.neon-pink-gradient {
                                    background: linear-gradient(
                                        271.83deg,
                                        #874c98 12.09%,
                                        #8e4a97 14.03%,
                                        #a94192 23.73%,
                                        #bc3b8f 34.4%,
                                        #c7378d 44.1%,
                                        #cb368c 54.77%,
                                        #e32c59 75.14%,
                                        #e6273c 99.39%
                                    );
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                }
                                &.neon-blue {
                                    color: #5fddeb;
                                }
                                display: block;
                                transform: translateY(100%);
                                animation: titleText 1s ease-out forwards;
                            }
                            &:nth-child(2) {
                                span {
                                    animation: titleText 1s ease-out 0.5s forwards;
                                }
                            }
                            &:nth-child(3) {
                                span {
                                    animation: titleText 1s ease-out 1s forwards;
                                }
                            }
                        }
                    }
                    .content {
                        display: block;
                        width: 100%;
                        max-width: 360px;
                        border-left: 1px solid ${(props) => props.textColor};
                        padding-left: 2rem;
                        opacity: 0;
                        animation: reveal 1s ease-out 1.5s forwards;
                        p {
                            font-family: 'Poppins';
                            font-size: 1.4rem;
                            line-height: 1.57;
                            font-weight: 400;
                            color: ${(props) => props.textColor};
                        }
                    }
                }
                &.image-container {
                    position: relative;
                    .image-overlay {
                        display: block;
                        position: relative;
                        width: 100%;
                        max-width: 575px;
                        margin: 0;
                        height: auto;
                        opacity: 0;
                        animation: reveal 0s linear 1s forwards;
                        overflow: hidden;
                        .image {
                            display: block;
                            width: 100%;
                            height: 100%;
                            transform: translateX(100%);
                            animation: transformImage 1s ease-out 1s forwards;
                            img {
                                display: block;
                                width: 100%;
                                height: auto;
                                margin: 0;
                            }
                        }
                    }
                }
            }
            .contact-links-container {
                display: block;
                position: relative;
                width: 100%;
                font-size: 0;
                margin-top: 4rem;
                opacity: 0;
                animation: reveal 1s ease-out 1.5s forwards;
                a {
                    display: block;
                    font-size: 1.4rem;
                    line-height: 1.36;
                    margin-bottom: 2.4rem;
                    color: #0e1828;
                    text-decoration: none;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    svg {
                        display: inline-block;
                        margin-right: 1.8rem;
                        vertical-align: middle;
                        width: auto;
                        height: 1.9rem;
                    }
                }
            }
            .scroll-down-button {
                display: none;
                font-size: 0;
                width: 5.4rem;
                height: 5.4rem;
                border: none;
                border-radius: 50%;
                background: transparent;
                border: 1px solid ${(props) => props.textColor};
                margin-top: 6rem;
                padding: 0;
                svg {
                    animation: bounce 5s ease-out forwards infinite;
                    path {
                        fill: ${(props) => props.textColor};
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        .outer-wrapper {
            .inner-wrapper {
                flex-flow: row nowrap;
                padding: 12.6rem 0 0 6rem;
                .column {
                    width: 40%;
                    max-width: 750px;
                    &.content-container {
                        flex: 1 1 auto;
                        padding-left: 10rem;
                        margin-left: auto;
                        span.intro {
                            display: block;
                        }
                        h1 {
                            font-size: 12rem;
                            margin-bottom: 6rem;
                        }
                    }
                    &.image-container {
                        align-self: stretch;
                        width: 60%;
                        margin-left: auto;
                        .image-overlay {
                            max-width: 100%;
                            margin: 0;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            height: 100%;
                            overflow: visible;
                            .image {
                                direction: rtl;
                                img {
                                    height: 100%;
                                    width: auto;
                                    max-width: unset;
                                    margin: 0 0 0 auto;
                                }
                            }
                        }
                    }
                }
                .contact-links-container {
                    a {
                        display: inline-block;
                        margin-right: 2.4rem;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .scroll-down-button {
                    display: block;
                }
            }
        }
    }
`;

const ContactMastheadLandingPage = ({ ...props }) => {
    const { height } = useWindowDimensions();

    function scrollDown(height) {
        window.scrollTo({ top: height, behavior: 'smooth' });
    }

    return (
        <Wrapper
            height={height}
            imageMobile={props.imageMobile}
            imageDesktop={props.imageDesktop}
            backgroundColor={props.backgroundColor}
            textColor={props.textColor}
            gradient={props.gradient}
        >
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="column content-container">
                        <span
                            className="intro"
                            dangerouslySetInnerHTML={{ __html: props.subheading }}
                        />
                        <h1 dangerouslySetInnerHTML={{ __html: props.heading }} />
                        <div className="content">
                            <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                        </div>
                        <div className="contact-links-container">
                            <a className="email" href="mailto:info@digital-velociy.co.uk">
                                <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M14.9161 3.28418H0.994547C0.498343 3.28418 0.104563 3.65409 0.0299835 4.13041L7.95531 9.35198L15.8806 4.13041C15.8061 3.65409 15.4123 3.28418 14.9161 3.28418ZM0 12.2258V5.30086L5.36377 8.83494L0 12.2258ZM15.9105 12.2258L10.5467 8.83494L15.9105 5.30086V12.2258ZM8.22876 10.3624L9.63582 9.43558L15.8786 13.3833C15.8021 13.8557 15.4103 14.2226 14.9161 14.2226H0.994535C0.500321 14.2226 0.10853 13.8557 0.0319615 13.3833L6.27477 9.43459L7.68184 10.3624C7.76537 10.4171 7.85984 10.4439 7.9553 10.4439C8.05076 10.4439 8.14523 10.4171 8.22876 10.3624Z"
                                        fill="#0E1828"
                                    />
                                </svg>
                                info@digital-velocity.co.uk
                            </a>
                            <a className="phone" href="tel:01134601202">
                                <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.89 11.1189C13.9104 11.1189 12.9485 10.9657 12.0371 10.6644C11.5905 10.5121 11.0414 10.6519 10.7688 10.9318L8.9698 12.2899C6.88344 11.1762 5.59827 9.89145 4.49977 7.82074L5.8179 6.06857C6.16035 5.72657 6.28319 5.22699 6.13602 4.75824C5.83352 3.84199 5.67986 2.88062 5.67986 1.90062C5.6799 1.19266 5.10394 0.616699 4.39603 0.616699H1.4577C0.749785 0.616699 0.173828 1.19266 0.173828 1.90057C0.173828 10.0152 6.77539 16.6167 14.89 16.6167C15.5979 16.6167 16.1738 16.0407 16.1738 15.3328V12.4027C16.1738 11.6948 15.5978 11.1189 14.89 11.1189Z"
                                        fill="#0E1828"
                                    />
                                </svg>
                                0113 460 1202
                            </a>
                            <a
                                className="directions"
                                href="https://goo.gl/maps/TfAk2zyFso84GyKdA"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.9939 5.61832C16.8643 4.18775 16.389 2.96756 15.784 1.91567C15.2223 1.49492 14.6606 1.15832 14.0556 0.905862C12.7593 0.316805 11.2902 0.022277 9.90745 0.106428C9.38892 0.148503 8.8704 0.190579 8.35188 0.316805C7.79015 0.443032 7.22841 0.611334 6.70989 0.863787C6.10495 1.11624 5.50001 1.49492 4.98148 1.8736C4.29012 3.00964 3.90123 4.2719 3.72839 5.57624C3.59876 6.54398 3.68518 7.51171 3.90123 8.52153C4.11728 9.48926 4.46296 10.4149 4.93827 11.2564L10.3395 20.0923L15.8272 11.2985C16.3026 10.457 16.6482 9.53134 16.8211 8.5636C16.9939 7.55379 17.0803 6.58605 16.9939 5.61832ZM10.3395 3.00964C12.3704 3.00964 14.0124 4.60851 14.0124 6.58605C14.0124 8.5636 12.3704 10.1625 10.3395 10.1625C8.30867 10.1625 6.66668 8.5636 6.66668 6.58605C6.66668 4.60851 8.30867 3.00964 10.3395 3.00964Z"
                                        fill="#0E1828"
                                    />
                                </svg>
                                Get Directions
                            </a>
                        </div>
                        <button className="scroll-down-button" onClick={() => scrollDown(height)}>
                            Scroll down
                            <svg width="10" height="26" viewBox="0 0 10 26">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.5014 21.9402L4.5014 0.420898L5.5014 0.420898L5.5014 21.9402L4.5014 21.9402Z"
                                    fill="#0E1828"
                                />
                                <path
                                    d="M9.70306 19.0469L5.00137 20.6744L0.299677 19.0469L5.00137 25.0144L9.70306 19.0469Z"
                                    fill="#0E1828"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="column image-container">
                        <div className="initial-box"></div>
                        <div className="image-overlay">
                            <div className="image">
                                <picture>
                                    <source srcSet={props.imageWebp} type="image/webp" />
                                    <source srcSet={props.imagePng} type="image/png" />
                                    <img src={props.imagePng} alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default ContactMastheadLandingPage;
