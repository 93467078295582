import React, { useEffect } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ContactMastheadLandingPage from '../components/content/mastheads/contactLandingPage';
import TextAndForm from '../components/custom/contact/TextAndForm';
import PageNavigationBlock from '../components/content/pageNavigationBlock';

import mastheadImageWebp from '../images/contact/contact-page-masthead.webp';
import mastheadImagePng from '../images/contact/contact-page-masthead.png';
import pageNavigationBlockImage from '../images/home/slides/home-slide-4.png';

const ContactPage = ({ transitionStatus, ...props }) => {
    useEffect(() => {
        console.log('Contact Page', transitionStatus);
    }, [transitionStatus]);

    return (
        <Layout path={props.path}>
            <Seo title="Contact" />
            <ContactMastheadLandingPage
                subheading="Contact"
                heading="<div class='line'><span>Let's get</span></div> <div class='line'><span class='neon-pink-gradient'>moving</span></div>"
                content="Let’s get things moving, if you would like to learn more or to work with us please get in touch and we’ll get the right solution for you."
                imageWebp={mastheadImageWebp}
                imagePng={mastheadImagePng}
                backgroundColor="#ECECEC"
                textColor="#0E1828"
                gradient="linear-gradient(271.83deg, #874C98 12.09%, #8E4A97 14.03%, #A94192 23.73%, #BC3B8F 34.4%, #C7378D 44.1%, #CB368C 54.77%, #E32C59 75.14%, #E6273C 99.39%)"
            />
            <TextAndForm heading="Leave us a message <span class='italic'>and we'll get back to you</span>" />
            <PageNavigationBlock
                backgroundColour="white"
                overlayTextColour="#E2246E"
                heading="We are problem solvers"
                subtitle="Next up - About"
                content="We’re a collaborative team of people all who share the same goal of getting things done and improving your business."
                image={pageNavigationBlockImage}
                linkText="Learn more about us"
                link="/about"
            />
        </Layout>
    );
};

export default ContactPage;
